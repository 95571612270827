@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
:root {
  --blue: #3948E6;
  --violet: #A345E6;
  --black: #000000;
  --white: #fff;
  --gray: #8D8D8D;
  --secondary-gray: #B3B3BF;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow-x: hidden;
}
